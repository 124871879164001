@import url(https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{background-color:#282c34;min-height:100vh;font-size:calc(10px + 2vmin);color:white}.container{display:flex;flex-direction:column;align-items:center;justify-content:center}.container .ultraways{margin:0.5rem 0 1rem;letter-spacing:3px;text-transform:uppercase;font-weight:bold;font-family:"Fredoka One",cursive}.controls{margin:0.75rem}.controls *{margin-right:0.5rem}.button{padding:.75rem;box-sizing:border-box;outline:none;border:none;border-radius:5px;cursor:pointer;font-size:1rem;color:white;background-color:#7289da;transition:background-color .17s ease, color .17s ease}.button:hover,.button:focus{background-color:rgba(114,137,218,0.9)}.button[disabled]{background-color:gray;cursor:auto;cursor:initial}.button[disabled]:hover{background-color:gray}.button.light{color:#080808;background-color:#d3d3d3}.button.light:hover,.button.light:focus{background-color:rgba(211,211,211,0.9)}.button.success{color:white;background-color:rgba(67,181,129,0.8)}.button.success:hover,.button.success:focus{background-color:rgba(67,181,129,0.7)}.button.danger{color:#080808;background-color:rgba(207,73,80,0.8)}.button.danger:hover,.button.danger:focus{background-color:rgba(207,73,80,0.7)}

